import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_EVENTS } from 'data/config/dataConfig'; // app modules

import { LIST_PAGE_KEY, MAP_PAGE_KEY, MEDIAS_PAGE_KEY, LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';
import Banner from 'src/components/banner/Banner';
import AdBanner from 'src/components/ad-banner/AdBanner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    background: 'url(' + getUrl('files/project/home/home_background.jpg') + ') no-repeat',
    backgroundSize: 'cover'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.9,
  // rowspan
  width: 1 // colspan

}; // TEMP FIXME (use jsontosass-loader ?)

var color1 = "#005EA7";
var color2 = "#122D62";
var tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))';

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {
        background: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ") no-repeat ").concat(backgroundPosition || '50% 50%', " ").concat(homebutton.bgcolor),
        backgroundSize: 'contain'
      }
    },
    icon: {
      className: 'home-tile-ad'
    }
  }, // Label (i18n)
  '', // Action to perform on click
  function (actions) {
    var url = homebutton['link_' + lang];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [
  /*{
      component: AdBanner,
      props: {
          forcedAdKey: 'header',
      }
  },*/
  {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ["inbox"]
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Exhibitors
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: tileBgGradient
          },
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Exposants.svg') + ')'
          } //                                className: 'home-icon-exposants',

        }
      }, // Label (i18n)
      'home.exhibitors', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_EXHIBITORS
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS),
      /*
      // Agenda (hors vols)
      createItemRoute(
          // Style
          {
              container: {
                  style: {
                      backgroundImage: tileBgGradient,
                  },
                  className: 'home-btn-text',
              },
              icon: {
                  style: {
                      backgroundImage: 'url('+getUrl('files/project/home/Programme.svg')+')',
                  },
      //                                className: 'home-icon-programme',
              },
          },
           // Label (i18n)
          'home.agenda',
           // Page & props
          LIST_GROUPS_PAGE_KEY,
          { input: { dataType: DATA_TYPE_EVENTS }},
           // Tile options
          GENERAL_TILE_OPTIONS,
      ),
      */
      // Map
      createItemRoute( // Style
      {
        container: {
          style: {
            backgroundImage: tileBgGradient
          },
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Plan.svg') + ')'
          } //                                className: 'home-icon-plan',

        }
      }, // Label (i18n)
      'home.map', // Page & props
      MAP_PAGE_KEY, null, // Tile options
      GENERAL_TILE_OPTIONS)
      /*
      // Services
      createItemRoute(
          // Style
          {
              container: {
                  style: {
                      backgroundImage: tileBgGradient,
                  },
                  className: 'home-btn-text',
              },
              icon: {
                  style: {
                      backgroundImage: 'url('+getUrl('files/project/home/Informations.svg')+')',
                  },
      //                                className: 'home-icon-services',
              },
          },
           // Label (i18n)
          'home.practicalInfo',
           // Page & props
          LIST_PAGE_KEY,
          { contextualTitle: "",
            inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }]},
           // Tile options
          GENERAL_TILE_OPTIONS,
      ),
       // Social Medias
      createItemRoute(
          // Style
          {
              container: {
                  style: {
                      backgroundImage: tileBgGradient,
                  },
                  className: 'home-btn-text',
              },
              icon: {
                  style: {
                      backgroundImage: 'url('+getUrl('files/project/home/SocialMedia.svg')+')',
                  },
      //                                className: 'home-icon-socialmedia',
              },
          },
           // Label (i18n)
          'home.medias',
           // Page & props
          MEDIAS_PAGE_KEY,
          null,
           // Tile options
          GENERAL_TILE_OPTIONS,
      ),*/
      ]
    }
  }, {
    component: AdBanner
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

var getEXH = function getEXH(lang, adConfig) {
  return {
    /* NO HOME PAGE */
  };
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @return {object}
 */
// export const get = (profile, lang, adConfig, orientation) => {
//     return getDefault(lang, adConfig);
// };


export var get = function get(profile, lang, adConfig, orientation) {
  switch (profile) {
    // PRO
    case 'webapp-exh':
      return getEXH(profile, lang, adConfig, orientation);

    default:
      return getDefault(lang, adConfig, orientation);
  }
};