
// conf
import config from 'data/config/config';

// app modules
import * as Db  from './Db';
import FileSystemHelper from './FileSystemHelper';

import { startUpdate,
         getUrl as updaterGetUrl } from './Updater';

import { addHttpIfNeeded, convertFileSrc } from 'src/core/util/JsTools';

// import TransitionQueuedActions from 'src/core/navigation/transition/TransitionQueuedActions';


const LOG_PREF = '[DataAssetsUtil] ';


export function getUrl(url, useHttp, absolute) {

    // Force data and assets to be fetched from backend if !CORDOVA and !DEV
    if (global.isCordovaContext !== true && process.env.NODE_ENV === 'production') {
        useHttp = true;
    }

    let value;

    if (!url) {
        value = '';
    }
    if (useHttp === true) {
        if (url[0] !== '/') {
                url = '/' + url;
        }
        value = addHttpIfNeeded(config.BO_URL + url, true);
    }
    else {
        // Remove starting '/' if any
        value = url[0] === '/' ? url.substr(1) : url;

        if (global.isCordovaContext) {
            value = updaterGetUrl(value);
        }
        if (
            absolute === true &&
            value[0] !== '/' &&
            value.indexOf('://') === -1
        ) {
            let loc = document.location.pathname;
            value = loc.substr(0, loc.lastIndexOf('/') + 1) + value;
        }
    }
    value = convertFileSrc(value);
    return value;
};

if (config.ENV === 'dev') {
    global.getUrl = getUrl;
}


export const init = () => {
    // defer
    window.setTimeout(() => {
        // TransitionQueuedActions.performActionWhenNoTransition(() => {
            console.log (LOG_PREF+'init');
            Db.initialize(() => {
                if (global.isCordovaContext) {
                    FileSystemHelper.initialize(() => {

                        // Dispatch DB updated event only once assets are ready
                        Db.dispatchDbUpdateEvent();

                        startUpdate();
                    });
                } else {
                    Db.dispatchDbUpdateEvent();
                }
            });
        // });
    }, 1);
};
