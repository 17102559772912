
import { CATEGORIES_DATA_TYPE, DATA_TYPE_EXHIBITORS } from 'data/config/dataConfig';
import { get as getSearchConfig, DATATYPES_WITH_PLACES } from 'data/config/searchConfig';

import { get as getCurrentProfile } from 'src/core/Profile';
import { get as getLabels } from 'src/core/Lang';

/**
 * Generate placeholder label  from data types declared in
 * @return {[type]} [description]
 */
export function getPlaceholder(type) {

    // Check if profile is set, because search configuration depends on profile
    let profile = getCurrentProfile();
    if (!profile) {
        return '';
    }

    let searchConfig = getSearchConfig(profile);

    let dataTypesWithoutCategories = Object.keys(searchConfig).filter(dataType => (
        CATEGORIES_DATA_TYPE.indexOf(dataType) === -1
    ));



    if(type == "searchPlace")
        dataTypesWithoutCategories = dataTypesWithoutCategories.filter(dataType => (
            DATATYPES_WITH_PLACES.indexOf(dataType) > -1
        ));


    


    let labels = getLabels();

    return dataTypesWithoutCategories
                .map((dataType, index) => {
                    let i18nLabel = labels.data[dataType].plural;
                    if (index > 0) {
                        i18nLabel = i18nLabel.toLowerCase();
                    }
                    if (dataType === DATA_TYPE_EXHIBITORS && labels.search.keywords) {
                        i18nLabel += `, ${labels.search.keywords}`
                    }
                    return i18nLabel;
                })
                .join(', ')
                + labels.search.placeholderSuffix;
}