export default {
  id: 'en',
  common: {
    appTitle: 'Nautic',
    colon: ': ',
    questionMark: '?',
    noResult: 'No result',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    keep: 'Keep',
    delete: 'Delete',
    chooseLang: 'Choose language',
    addToFavorites: 'Add to favorites',
    defaultPlaceLabel: 'Map',
    noNetworkTitle: 'Network unavailable',
    noNetwork: 'Please try again later',
    loading: 'Please wait...',
    showAll: 'Show all on map',
    noPlacesToShowOnMap: 'No related place to show on map',
    termsOfUse: 'Terms of use',
    information: 'Information',
    contact: 'Contact',
    display: 'Display',
    switch2Columns: 'Switch to a page setup optimized for your screen width? (recommended)',
    share: 'Share',
    goBack: 'Go back',
    goToHome: 'Go to home',
    goToRootCategory: 'Go back to section home',
    login: 'Log in',
    goToFavorites: 'See your favorites',
    goToInbox: 'See messages',
    goToSearch: 'Go to search page',
    openMenu: 'Open menu',
    relatedContent: 'Related Content',
    contributions: 'Contributions',
    feedback: 'Feedback',
    rateThisSession: 'Rate this session',
    seeMore: 'see more ...',
    seeLess: 'see less ...'
  },
  location: {
    yourLocation: 'Your position'
  },
  disclaimer: {
    text: 'Cookies are used with this app to compute anonymous usage statistics.'
  },
  share: {
    mail: 'Share by email',
    sms: 'Share by sms',
    facebook: 'Share on Facebook',
    linkedin: 'Share on LinkedIn',
    twitter: 'Share on Twitter',
    positionShare: 'Position shared',
    // favorite position share description
    shareFail: 'Failed to share',
    linkedinShareDone: 'Page shared on your LinkedIn profile',
    linkedinShareFail: 'Failed to share on LinkedIn',
    facebookShareDone: 'Page shared on your Facebook profile',
    facebookShareFail: 'Failed to share on Facebook'
  },
  data: {
    brands: {
      singular: 'Brand',
      plural: 'Brands',
      title: 'Brands'
    },
    brand_categories: {
      singular: 'Brand category',
      plural: 'Brand categories',
      title: 'Brands'
    },
    exhibitors: {
      singular: 'Exhibitor',
      plural: 'Exhibitors',
      title: 'Exhibitors',
      searchPlaceholder: 'Exhibitors, keywords'
    },
    exhibitor_categories: {
      singular: 'Exhibitor category',
      plural: 'Exhibitor categories',
      title: 'Exhibitors'
    },
    restaurants: {
      singular: 'Restaurant',
      plural: 'Restaurants',
      title: 'Restaurants'
    },
    restaurant_categories: {
      singular: 'Restaurant category',
      plural: 'Restaurant categories',
      title: 'Restaurants'
    },
    services: {
      singular: 'Service',
      plural: 'Services',
      title: 'Services'
    },
    service_categories: {
      singular: 'Service category',
      plural: 'Service categories',
      title: 'Services'
    },
    aircrafts: {
      singular: 'Aircraft',
      plural: 'Aircraft',
      title: 'Aircraft'
    },
    aircraft_categories: {
      singular: 'Aircraft category',
      plural: 'Aircraft categories',
      title: 'Aircraft'
    },
    events: {
      singular: 'Event',
      plural: 'Events',
      title: 'Events',
      subevents: 'Speeches'
    },
    event_categories: {
      singular: 'Event category',
      plural: 'Event categories',
      title: 'Events'
    },
    conferences: {
      singular: 'Conference',
      plural: 'Conferences'
    },
    speakers: {
      singular: 'Speaker',
      plural: 'Speakers',
      title: 'Speakers',
      moderators: 'Moderators'
    },
    newproducts: {
      singular: 'Product',
      plural: 'Products',
      title: 'Products'
    },
    newproduct_categories: {
      singular: 'Product category',
      plural: 'Product categories',
      title: 'Products'
    },
    animations: {
      plural: 'Experiences',
      title: 'Experiences',
      singular: 'Experience',
      go: 'See on map'
    },
    favoritepositions: {
      plural: 'Locations',
      singular: 'Location'
    },
    flights: {
      singular: 'Flight',
      plural: 'Flights'
    },
    flights_schedule: {
      locale: 'en',
      // wtf ?
      longDateFormat: 'L h:mm:ss a',
      title: 'Flights Schedule',
      refresh: 'Refresh',
      unknownAircraft: 'Unknown aircraft',
      notScheduledYet: 'Not scheduled yet',
      errorWhileFetching: 'An error occurred',
      lastRefreshed: 'Last update : ',
      noResults: 'Flights are not scheduled yet'
    },
    docunits: {
      singular: 'Press kit',
      plural: 'Press kits'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' bytes',
    kilobytes: ' kB',
    megabytes: ' mB'
  },
  update: {
    modalTitle: 'Update',
    update_detected: 'Proceed to data update ?',
    cancel_update: 'Cancel update',
    update_done: 'Update completed',
    new_version_detected: 'New version detected',
    timeout: 'Timeout'
  },
  lang: {
    fr: 'french',
    en: 'english',
    zh: 'chinese'
  },
  menu: {
    restaurants: 'Restaurants',
    hours: 'Opening hours',
    inbox: 'Inbox',
    brands: 'Brands',
    exhibitors: 'Exhibitors',
    exhibitorsAndNp: 'Exhibitors & products',
    services: 'Practical info.',
    speakers: 'Speakers',
    map: 'Map',
    agenda: 'Conferences <br>& Round tables',
    experiences: 'Experiences',
    medias: 'Social Media',
    update: 'Update',
    language: 'Language',
    close: 'Close menu',
    pmr: 'MIP',
    toggleProfile: 'Change profile',
    aircraftsAndFlights: 'Aircraft & Flights',
    livewall: 'LiveWall',
    twitter: 'Twitter',
    presskits: 'Press kits',
    cgu: 'Terms of use'
  },
  home: {
    title: 'Home',
    myVisit: 'My visit',
    agenda: 'CONFERENCES & ROUND TABLES',
    experiences: 'EXPERIENCES',
    exhibitors: 'EXHIBITORS',
    exhibitorsAndProducts: 'EXHIBITORS & PRODUCTS',
    mapsAndGroups: 'Map & Groups',
    map: 'MAP',
    aircraftsAndFlights: 'AIRCRAFT & FLIGHTS',
    pmr: 'MIP',
    practicalInfo: 'PRACTICAL INFORMATION',
    medias: 'SOCIAL MEDIA',
    presskits: 'PRESS KITS'
  },
  profile: {
    title: 'Choose profile',
    mainLabel: 'Select your profile',
    codeInput: 'Enter the access code',
    invalidCode: 'Invalid access code',
    pro: 'Pro',
    gp: 'Public',
    presse: 'Press'
  },
  exhibitor: {
    contacts: 'contacts',
    entities: 'entities',
    agentCountries: 'agent countries',
    brands: 'brands',
    activities: 'activities',
    categories: 'categories'
  },
  paper: {
    categories: 'categories',
    openPdf: 'OPEN PDF',
    authors: ''
  },
  placeSearch: {
    editStart: 'Edit start',
    setDestination: 'Next',
    itinerary: 'Itinerary',
    start: 'Choose your start point',
    destination: 'Destination',
    poi: 'POI'
  },
  aircraft: {
    pilot: 'Pilot',
    coPilot: 'Copilot',
    country: 'Country',
    manufacturer: 'Manufacturer',
    category: 'Category',
    usage: 'Usage',
    wingSpan: 'Wing span',
    length: 'Length',
    emptyWeight: 'Empty weight',
    maxCruiseSpeed: 'Max.cruise speed',
    characteristics: 'Characteristics',
    messageNoFlightYet: 'This aircraft is planned for flying displays. More information to come.'
  },
  newproducts: {
    launchDate: 'Launch date'
  },
  events: {
    to: 'to',
    access: 'Access',
    publics: 'Public'
  },
  map: {
    title: 'Map',
    goTo: 'Go there',
    shouldReload: 'Map data has been updated, would you like to reload now?',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'No itinerary found.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: 'You are not located.'
    }
  },
  favorites: {
    title: 'Favorites',
    download: 'Download (excel)',
    yourFavs: 'Your favorites',
    other: 'Other'
  },
  agenda: {
    title: 'Events'
  },
  medias: {
    title: 'Social Media',
    label: 'Follow our news on'
  },
  pmr: {
    title: 'MIP'
  },
  search: {
    title: 'Search',
    placeholderSuffix: '... (min. 2 char.)',
    submitLabel: 'Ok',
    searchFor: 'Search for ',
    keywords: 'Keywords'
  },
  hours: {
    title: 'Opening hours'
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: '',
  inbox: {
    title: 'Inbox',
    empty: 'You have no message',
    dismiss: 'Dismiss',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Login',
    fillTheFields: 'Please fill in both fields before submitting',
    badLogin: 'The login or password you entered are incorrect',
    loginText: 'Login',
    passwordText: 'Password',
    loginButton: 'Login',
    loginSuccess: 'You are logged-in',
    loginToSyncYourFavs: 'Enter the same username and password created when applying for the badge to synchronize your favourites between eurosatory.com & the mobile app!',
    introductoryText: 'Your favourites now synchronize automaticaly across your different web & mobile sessions.',
    forgottenPassword: 'I forgot my password',
    createAccount: 'Create an account',
    tos: 'Terms of use',
    profile: 'Profile',
    logout: 'Logout',
    noNetwork: 'You are not connected to the Internet',
    loading: 'Checking...'
  },
  profileDialog: {
    title: 'Profile',
    fields: {
      title: 'Civility',
      firstName: 'First Name',
      lastName: 'Last Name',
      organisation: 'Organisation',
      position: 'Position',
      email: 'Email',
      phone: 'Phone'
    },
    confirm: 'Confirm'
  },
  appointment: {
    button: 'Appointment request',
    setProfileFirst: 'You must fill-in your profile to use this feature.',
    sent: function sent(formatedDate, email, phone) {
      return "Appointment request sent ".concat(formatedDate, ". Your recipient will answer you by email at ").concat(email) + (phone ? " or by phone ".concat(phone) : '') + '.';
    },
    errors: {
      GENERIC: 'Could not send the appointment request.',
      INVALID_REQUEST: 'An error occured while sending the appointment request.',
      EXHIBITOR_NOT_FOUND: 'An error occured while sending the appointment request.',
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: 'An error occured while sending the appointment request.',
      ALREADY_SENT: 'An appointment request has already been sent to this exhibitor.'
    }
  },
  synchroFavs: {
    codePlaceholder: 'Enter code',
    favoritesPageNoticeUnauthorizedMode: 'You may synchronize your favorites between your mobile and web sessions by clicking on',
    favoritesPageNoticeExtraUnauthorizedMode: 'Your favorites are not shared with any third party nor used for any goal. You may stop the synchronization at any time by clicking on',
    favoritesPageNotice: 'Once logged-in, your favourites are synchronized across your user sessions on mobile and web.',
    logoutConfirm: 'Your favourites are no longer synchronized on this terminal. Do you want to keep them locally or delete them ?',
    logoutConfirmUnauthorizedMode: 'Your favourites will no longer be synchronized on this terminal. Do you want to keep them locally or delete them ?',
    status: {
      ongoing: 'Synchronizing your favourites',
      failed: 'Your favourites are not synchronized, check your data connection',
      success: 'Your favourites are synchronized'
    },
    haveCode: 'I have a code',
    noCode: 'I don\'t have a code',
    syncCode: 'Synchronization code',
    localSyncCode: {
      singular: 'Used synchronization code',
      plural: 'Used synchronization codes'
    },
    enterCodeNotice: 'Enter your code to synchronize your favorites',
    codeCreatedNotice: 'The code below was created for you. Please press OK to synchronize your favorites',
    invalidCode: 'Invalid code',
    loadingCode: 'Loading code ...',
    comfirmDisableSynchro: 'Do you really want to disable your favorites\' synchronization ?',
    favoritesPageNoticeCodeSynchronised: 'Synchronized with code',
    onlyDigits: 'Numbers only'
  },
  poll: {
    listTile: 'Available Polls',
    noPolls: 'No poll available',
    sessionTitle: 'Session rating',
    sessionCodeError: 'INVALID CODE',
    fillTheFields: 'Please enter a code before submitting',
    code: 'code',
    enterCode: 'Please enter the session code',
    pleaseRate: 'Please rate',
    back: 'back',
    next: 'next',
    commentPlaceholder: 'your comment',
    thank: 'Thank you',
    submit: 'Submit',
    submitted: 'Already Submitted',
    done: 'Done',
    noNetwork: 'You have no internet connexion. You can press cancel now and Submit later. Your answers have been saved.',
    willOpen: 'This poll will be opened on',
    willClose: 'This poll will be closed on',
    isClosed: 'This poll is closed',
    requireAuthentication: 'Please Log In.',
    started: 'Started',
    notStarted: 'Not started',
    serverError: 'Server error. You can press cancel now and Submit later. Your answers have been saved.'
  },
  contributions: {
    lastRefreshed: 'Last update time',
    refresh: 'Refresh',
    ps: 'Current preferential subject (PS)',
    current: 'Current contribution',
    upcoming: 'Upcoming contributions',
    past: 'Past contributions',
    cancelled: 'Cancelled contributions',
    undefined: 'Undefined contributions',
    more: 'More',
    notBefore: 'not before',
    programmeSlogan: 'To get the Contributions schedule in real time, please go to each Group Discussion Meeting page in Technical Programme / Group Discussion Meetings',
    empty: 'No contributions scheduled yet'
  },
  docunit: {
    documents: 'Documents',
    document: 'Document',
    contacts: 'Contacts',
    presskit: 'Press kit'
  }
};