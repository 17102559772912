module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    appleTeamId: '2BAP3P29V2',
    name: {
      default: 'nautic'
    },
    id: 'com.mobilespot.eventmap.dev'
  },
  web: {
    url: 'nautic.mobile-spot.com' // deploy: {
    //     host: 'kim1',
    //     user: 'www',
    //     path: '/home/www/mobigeo/siae',
    // },

  }
});