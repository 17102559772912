import React from 'react';
import PropTypes from 'prop-types';
import InstagramEmbed from 'react-instagram-embed';

class InstagramStory extends React.Component {
    render() {
        const { id } = this.props

        let width = Number(document.documentElement.clientWidth - 30);

        if (width > 500) { 
            width = 500;
        }

        return (
            <div className='media-instagram-post' id={id}>
                <InstagramEmbed
                    url={id}
                    maxWidth={width}
                    hideCaption={false}
                    protocol='https:'
                    injectScript={false}
                    onAfterRender={()=>{
                        const story = document.getElementById(id);
                        const iframes = story.getElementsByTagName("iframe") || [];
                        for (let ifr of iframes) {
                            ifr.style.width = `${width}px`;
                            ifr.style.maxWidth = `${width}px`;
                        }
                    }}
                    style={{
                        width,
                        maxWidth: width  
                    }}
                />
            </div>
        );
    }

}

InstagramStory.propTypes = {
    id: PropTypes.string.isRequired
};

export default InstagramStory;
