import _defineProperty from "/Users/etiennelescot/Documents/mobigeo-sdk-eventmap/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { DATA_TYPE_ANIMATIONS, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_AIRCRAFTS, DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_DOCUNITS } from 'data/config/dataConfig'; // Perform search when user has typed at least n characters

export var SEARCH_MIN_CHARS = 2; //export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */

export var DATATYPES_WITH_PLACES = [//    DATA_TYPE_AIRCRAFTS,
//    DATA_TYPE_ANIMATIONS,
DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_SERVICES]; // Common 'title'

var getTitleAttribute = function getTitleAttribute(item) {
  return {
    text: item.title
  };
};

var getExhibitorAttributes = function getExhibitorAttributes(item) {
  var fields = {
    text: item.title
  };

  if (item.description) {
    fields.keywords = [item.description];
  }

  if (item.lump.catTag && item.lump.catTag.length > 0) {
    fields.keywords = fields.keywords ? fields.keywords.concat(item.lump.catTag) : item.lump.catTag;
  }

  return fields;
};
/**
 * Expose:
 *  - data types
 *  - and functions returning the value
 * on which search is performed
 */


var conf = function conf(profile) {
  var _config;

  var config = (_config = {}, _defineProperty(_config, DATA_TYPE_EXHIBITORS, getExhibitorAttributes), _defineProperty(_config, DATA_TYPE_EXHIBITOR_CATEGORIES, getTitleAttribute), _config);

  if (profile === 'presse') {
    config[DATA_TYPE_DOCUNITS] = getTitleAttribute;
  }

  return config;
};

export var get = conf;