
import config from 'data/config/config';


const LOG_PREF = '[PushWooshManager] ';


function isPushWooshEligible() {
    return global.isCordovaContext && config.appId && config.projectId
}


/**
 * Perform device register
 */
export function init() {
    if (isPushWooshEligible()) {

        let pushwoosh;
        try {
            pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');

            pushwoosh.onDeviceReady({
                appid    : config.appId,
                projectid: config.projectId,
            });

            pushwoosh.registerDevice(
                function pwRegisterSuccess(status) {
                    console.debug(LOG_PREF+'Registered with push token: ', status.pushToken);
                },
                function pwRegisterError(error) {
                    console.error(LOG_PREF+'Failed to register: ',  error);
                }
            );
            console.info(LOG_PREF+'initialized');

        } catch (e) {
            console.error(LOG_PREF+'Failed to load PushWoosh cordova plugin', e);
        }
    } else {
        console.debug('Skipped PushWoosh registration');
    }
}


export function setProfileTag(value) {
    if (isPushWooshEligible()) {

        let pushwoosh;
        try {
            pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');

            pushwoosh.setTags({ Profile: value },
                function pwSetTagsSuccess(status) {
                    console.info(LOG_PREF+'setTags success', status);
                },
                function pwSetTagsError(error) {
                    console.info(LOG_PREF+'setTags failed', error);
                }
            );
        } catch (e) {
            console.error(LOG_PREF+'Failed to load cordova plugin PushWoosh', e);
        }
    }
}
