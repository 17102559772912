var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = 'ephj2019-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'ephj2019-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'eventmap-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/eventmap/eventmap-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/eventmap/eventmap-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-24',
  projectId: 1017555973100,
  // FCM sender id
  appId: '9C686-109FC' // pushwoosh appid

});