
import React from 'react'
import PropTypes from 'prop-types';

import { formatDayLong } from 'src/core/Lang';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

/**
 * Used when a value is modified by List component
 * (e.g a string being added ...<b>...</b>... as a search result)
 * @type {String}
 */
export const ORIGINAL_PROP_PREFIX = 'original';


const getFavIconCssClasses = (itemIsFavorite) => {
    let favIconClassname = ['star', 'icon-font'];
    if (itemIsFavorite) {
        favIconClassname.push('is-fav');
    }
    return favIconClassname;
};

/**
 * This is a class instead of a stateless component because `ref` callback
 * is needed for ListElement of type separators. @see AlphabeticalList `getScrollTopValues`
 */
class ListElement extends React.PureComponent {

    // shouldComponentUpdate(nextProps) {
    //     return JSON.stringify(this.props) !== JSON.stringify(nextProps);
    // }

    render() {

        let {
            text,
            originaltext,
            textMinor,
            textMinorBis,
            textStyle,
            originaltextMinor,
            hasFavoriteButton,
            showGoToButton,
            goToButtonLabel,
            event,
            isFavorite,
            isFastAndUgly,
            isSeparator,
            image,
            image2,
            image3,
            counter,
            isClickable = true,
            className = '',
            ...attrs
        } = this.props;

        const
            showFavoriteIcon = !isFastAndUgly && hasFavoriteButton === true,
            showImage        = !isFastAndUgly && typeof image === 'string',
            showImage2       = !isFastAndUgly && typeof image2 === 'string',
            showImage3       = !isFastAndUgly && typeof image3 === 'string',
            showCounter      = typeof counter === 'number';


        if (isSeparator) {
            return <li className="horizontal-separator" {...attrs}><span className="separator-text">{text}</span></li>;

        // Minimal fast&ugly mode for very long lists
        } else if (isFastAndUgly) {
           return <li data-is-clickable={isClickable === true ? '' : null} {...attrs}><span>{text}</span></li>;

        } else {
            return (
                <li
                    title={originaltext || text}
                    className={className + (!showFavoriteIcon && !showImage && !event ? 'no-previous-sibling' : '')}
                    data-is-clickable={isClickable === true ? '' : null}
                    {...attrs} >

                    { showFavoriteIcon && <span className={getFavIconCssClasses(isFavorite).join(' ')}>e</span> }

                    {/* Event time */}
                    { event &&
                            <span className="list-el-event">
                                <div className="start-date" dangerouslySetInnerHTML={{ __html: formatDayLong(event.start_date) }} />
                                <div className="start-hour">{event.start_time}</div>
                                { event.start_date !== event.end_date && <div className="end-date" dangerouslySetInnerHTML={{ __html: formatDayLong(event.end_date) }} /> }
                                <div className="end-hour">{event.end_time}</div>
                            </span>
                    }

                    <span className="list-el-text">
                        <span style={textStyle} dangerouslySetInnerHTML={{ __html: text }}></span>
                        <div className="list-el-details">
                            { textMinor && <span className="text-minor" dangerouslySetInnerHTML={{ __html: textMinor }} /> }
                            { textMinorBis && <span className="text-minor-bis" dangerouslySetInnerHTML={{ __html: textMinorBis }} /> }
                        </div>
                    </span>

                    <div className="list-el-icons">
                        { showImage2 && <img className="list-el-image-small" alt="" src={ getUrl(image2) } /> }

                        { showImage3 && <img className="list-el-image-small" alt="" src={ getUrl(image3) } /> }
                    </div>

                    { showImage && <img className="list-el-image" alt="" src={ getUrl(image) } /> }

                    { showCounter && <span className="list-el-counter"><span>{counter}</span></span> }

                    {/* Itinerary button */}
                    { showGoToButton === true && <span className='go-to'>{goToButtonLabel}</span> }

                    { isClickable && <span className="list-el-chevron fa fa-chevron-right"></span>  || <span className="list-el-chevron fa fa-chevron-right nochevron"></span> }
                </li>
            );
        }
    };
}

/**
 * @see app-react/data/config/listConfig.getHelpers
 */
ListElement.propTypes = {
    id               : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    text             : PropTypes.string.isRequired,
    originaltext     : PropTypes.string,
    textMinor        : PropTypes.string,
    originaltextMinor: PropTypes.string,
    className        : PropTypes.string,
    hasFavoriteButton: PropTypes.bool,
    isFavorite       : PropTypes.bool,
    isSeparator      : PropTypes.bool,
    showGoToButton   : PropTypes.bool,
    goToButtonLabel  : PropTypes.string,
};

export default ListElement;
