
import {
    CLEAR_PLACE_RESULTS,
    DATA_UPDATED,
    HIDE_SEARCH_PLACE_DIALOG,
    LANG_CHANGED,
    MAP_USER_LOCATED,
    MAP_USER_UNLOCATED,
    PLACE_SEARCH_PERFORMED,
    SEARCHED_PLACE_SELECTED,
    SET_SEARCH_PLACE_DIALOG_CANCELABLE,
    SHOW_SEARCH_PLACE_DIALOG,
    TOGGLE_PMR_STATUS,
    WINDOW_RESIZED,
} from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import {
    langChanged,
    setSearchPlacePlaceHolder,
    updateObject,
    windowResized,
    togglePMRStatus,
} from 'src/store/reducers/commons';

import SearchPlaceTypes from './SearchPlaceTypes';

const Immutable = require('immutable');


const getDefaultState = () => ({
    labels: getLabels(),
    isOpen: false,
    selectedEntries: Immutable.Map({}),
    searchType: null,
    userIsLocated: false,
});


const _showSearchPlaceDialog = (state, action) => {
    return updateObject(state, {
        isOpen      : true,
        isCancelable: false,
        searched    : null,
        results     : null,
        resultsType : null,
        searchType  : action.searchType,
    });
};

const _hideSearchPlaceDialog = (state, action) => {
    return updateObject(state, {
        isOpen: false,
        selectedEntries: {},
        searchType: null,
    });
};

const _setSearchPlaceDialogCancelable = (state, action) => {
    return updateObject(state, { isCancelable: action.value });
};

const _placeSearchPerformed = (state, action) => {
    return updateObject(state, {
        status     : action.status,
        searched   : action.searched,
        results    : action.results,
        resultsType: action.searchType,
        totalCount : action.totalCount,
        relaunch   : false,
    });
};

const _clearPlaceResults = (state, action) => {
    let selectedEntries = state.selectedEntries.toJS ? state.selectedEntries.toJS() : {};
    selectedEntries[action.searchType] = {};

    return updateObject(state, {
        status     : action.status,
        results    : null,
        resultsType: null,
        searched   : null,
        totalCount : 0,
        selectedEntries: Immutable.Map(selectedEntries),
    });
};


const _searchedPlaceSelected = (state, action) => {
    if (action.searchType === SearchPlaceTypes.poi) {
        // Let the middleware handle this one
        return state;
    }

    let selectedEntries = state.selectedEntries.toJS ? state.selectedEntries.toJS() : {};
    selectedEntries[action.searchType] = action.entry;

    return updateObject(state, {
        results    : null,
        resultsType: null,
        searched   : null,
        totalCount : 0,
        selectedEntries: Immutable.Map(selectedEntries),
    });
};



export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CLEAR_PLACE_RESULTS        : return _clearPlaceResults(state, action);
        case DATA_UPDATED               : return setSearchPlacePlaceHolder(state, action);
        case HIDE_SEARCH_PLACE_DIALOG   : return _hideSearchPlaceDialog(state, action);
        case LANG_CHANGED               : return langChanged(state, action);
        case MAP_USER_LOCATED           : return updateObject(state, { userIsLocated: true });
        case MAP_USER_UNLOCATED         : return updateObject(state, { userIsLocated: false });
        case PLACE_SEARCH_PERFORMED     : return _placeSearchPerformed(state, action);
        case SEARCHED_PLACE_SELECTED    : return _searchedPlaceSelected(state, action);
        case SET_SEARCH_PLACE_DIALOG_CANCELABLE: return _setSearchPlaceDialogCancelable(state, action);
        case SHOW_SEARCH_PLACE_DIALOG   : return _showSearchPlaceDialog(state, action);
        case TOGGLE_PMR_STATUS          : return togglePMRStatus(state, action);
        case WINDOW_RESIZED             : return windowResized(state, action);
        default: return state;
    }
};
